// Colors
$irg-white: 		#FFFFFF;
$irg-body-gray: 	#FAFAFA;
$irg-muted: 		#919EAB;
$irg-gray: 			#808080;
$irg-gray-light: 	#d4d4d4;
$irg-gray-lighter:	#f9fafb;
$irg-purple: 		#A100FF;
$irg-light-purple: 	rgba($irg-purple, 0.075);
$irg-shadow-color:	rgba(#c1c1c1, 0.45);

// Breakpoints
$breakpoints: (
	sm: 540px,
	md: 720px,
	lg: 960px,
	xl: 1200px,
	xxl: 1720px
)