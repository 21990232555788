@import '../Styles/variables';

.chat-conversation{
    flex: 1 1 auto;
    max-height: 100vh;
    padding: 8px 10px;

    .conversation-box {
        display: flex;
        flex-direction: column;

        .chatbox{
            padding: 10px;
            margin: 10px 0 4px;
            font-size: 13px;
        }

        .timeStamp{
            font-size: 10px;
            color: $irg-gray-light;
            font-weight: bold;
        }

        &.agent-box {
            align-items: flex-end;

            .chatbox{
                color: $irg-white;
                background: $irg-muted;
                box-shadow: 0px 0px 15px 5px $irg-shadow-color;
                border-radius: 15px 15px 5px 15px;
            }

            .timeStamp{
                text-align: right;
            }

        }
        &.customer-box {
            align-items: flex-start;

            .chatbox{
                background: $irg-white;
                box-shadow: 0px 0px 10px 1px $irg-shadow-color;
                border-radius: 15px 15px 15px 5px;
            }

            .timeStamp{
                text-align: left;
            }
        }
    }

    @media (min-width: map-get($breakpoints, 'md')) {
        flex-basis: 0;
        max-height: auto;
    }
}

.overall{
    font-size: 11px;
}

.opacityInitial{
    opacity: 0.5;
}