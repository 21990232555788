@import 'variables';

// Bootstrap configuration
$container-max-widths: $breakpoints;

$purple: $irg-purple;
$primary: $purple;

$border-radius: 8px;

@import "~bootstrap/scss/bootstrap.scss";