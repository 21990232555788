@import 'variables';

html,
body {
    height: 100%;
}

.text-purple {
    color: $irg-purple;
}

.bg-purple {
    background-color: $irg-purple;
}

.irg-root {
    display: flex;
    flex-direction: column;
	min-height: 100%;

    @media (min-width: map-get($breakpoints, 'md')) {
        height: 100%;
    }
}

.card-container{
    box-shadow: 0px 0px 15px 1px $irg-shadow-color;
    border-radius: 8px;
}

.line-height-1 {
    line-height: 1;
}

::-webkit-scrollbar{
    width: 7px;
    border-radius: 15px;
}

::-webkit-scrollbar-track {
    background: $irg-white;
    border-radius: 15px;
}

::-webkit-scrollbar-thumb {
    background: $irg-gray-light;
    border-radius: 15px;
}

::-webkit-scrollbar-thumb:hover {
    background: $irg-gray;
    border-radius: 15px;
}