@import '../Styles/variables';

.product-frame{

    .product-image{
        position: relative;
        box-shadow: 0px 0px 7px 0px $irg-shadow-color;
        border-radius: 5px;
        background-size: cover;
        background-color: $irg-white;

        &::after {
            content: '';
            display: block;
            padding-bottom: 100%;
        }
    }

    .product-desc{
        font-size: 11px;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
    }
}

.product-suggestion{

    .product-suggestion-body{
        overflow-y: auto;
        overflow-x: hidden;
    }
}

