@import '../Styles/variables';

.virtual-agent {
    height: 100%;

    .agent-video-container {
        position: relative;
        height: 800px;
        max-height: 100%;
        aspect-ratio: 6 / 8;
    }

    .progress-container {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;

        progress {
            width: 100%;
            height: 2rem;
            color: $irg-purple;
            accent-color: $irg-purple;
        }
    }
}

